"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.escapeRegexp = escapeRegexp;
/**
 *
 * @param str
 */
function escapeRegexp(str) {
  return str.replace(/[[\]/{}()*+?.\\^$|]/g, '\\$&');
}
