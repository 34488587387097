"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isRoute = isRoute;
const is_present_1 = require("./is-present");
/**
 *
 * @param route
 */
function isRoute(route) {
  return (0, is_present_1.isPresent)(route) && typeof route !== 'string';
}
