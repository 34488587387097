import { trigger, transition, style, animate, state } from '@angular/animations';
const expandCollapseAnimation = trigger('expandCollapse', [transition(':enter', [style({
  opacity: '{{opacity}}',
  height: '{{from}}'
}), animate('225ms cubic-bezier(0.4,0.0,0.2,1)', style({
  opacity: 1,
  height: '*'
}))], {
  params: {
    from: 0,
    opacity: 0
  }
}), transition(':leave', [style({
  opacity: 1,
  height: '*'
}), animate('225ms cubic-bezier(0.4,0.0,0.2,1)', style({
  opacity: '{{opacity}}',
  height: '{{from}}'
}))], {
  params: {
    from: 0,
    opacity: 0
  }
}), state('true', style({
  opacity: 1,
  height: '*'
})), state('false', style({
  opacity: '{{opacity}}',
  height: '{{from}}'
}), {
  params: {
    from: 0,
    opacity: 0
  }
}), transition('* => true', [animate('225ms cubic-bezier(0.4,0.0,0.2,1)')]), transition('* => false', [animate('225ms cubic-bezier(0.4,0.0,0.2,1)')])]);
const fadeAnimation = trigger('fadeAnimation', [transition(':enter', [style({
  opacity: 0
}), animate('220ms cubic-bezier(0.25, 0.8, 0.25, 1)', style({
  opacity: 1
}))]), transition(':leave', [style({
  opacity: 1
}), animate('220ms cubic-bezier(0.25, 0.8, 0.25, 1)', style({
  opacity: 0
}))])]);
const dropdownOpenAnimation = [style({
  transform: 'scale(0.9)',
  opacity: 0
}), animate('120ms cubic-bezier(0.25, 0.8, 0.25, 1)', style({
  transform: 'scale(1)',
  opacity: 1
}))];
const dropdownCloseAnimation = [style({
  transform: 'scaleY(1)',
  opacity: 1
}), animate('120ms cubic-bezier(0.25, 0.8, 0.25, 1)', style({
  transform: 'scaleY(0.9)',
  opacity: 0
}))];
const tooltipOpenAnimation = [style({
  transform: 'scale(0.8)',
  opacity: 0
}), animate('120ms cubic-bezier(0.25, 0.8, 0.25, 1)', style({
  transform: 'scale(1)',
  opacity: 1
}))];
const tooltipCloseAnimation = [style({
  transform: 'scale(1)',
  opacity: 1
}), animate('120ms cubic-bezier(0.25, 0.8, 0.25, 1)', style({
  transform: 'scale(0.8)',
  opacity: 0
}))];
const notificationOpenAnimation = [style({
  transform: 'scale(0.7)',
  opacity: 0
}), animate('120ms cubic-bezier(0.25, 0.8, 0.25, 1)', style({
  transform: 'scale(1)',
  opacity: 1
}))];
const notificationCloseAnimation = [style({
  transform: 'scale(1)',
  opacity: 1
}), animate('120ms cubic-bezier(0.25, 0.8, 0.25, 1)', style({
  transform: 'scale(0.7)',
  opacity: 0
}))];
const popupAnimation = trigger('popupAnimation', [transition(':enter', [style({
  transform: 'scale(0.9)'
}), animate('220ms cubic-bezier(0.25, 0.8, 0.25, 1)', style({
  transform: 'scale(1)'
}))])]);
const preventInitialChildAnimations = trigger('preventInitialChild', [transition(':enter', [])]);
const tabFadeAnimation = trigger('tabFadeAnimation', [transition(':enter', [style({
  opacity: 0
}), animate('220ms cubic-bezier(0.25, 0.8, 0.25, 1)', style({
  opacity: 1
}))]), transition(':leave', [style({
  opacity: 1
}), animate('220ms cubic-bezier(0.25, 0.8, 0.25, 1)', style({
  opacity: 0
}))])]);

/**
 * Generated bundle index. Do not edit.
 */

export { dropdownCloseAnimation, dropdownOpenAnimation, expandCollapseAnimation, fadeAnimation, notificationCloseAnimation, notificationOpenAnimation, popupAnimation, preventInitialChildAnimations, tabFadeAnimation, tooltipCloseAnimation, tooltipOpenAnimation };
