"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.unique = unique;
const as_array_1 = require("./as-array");
/**
 * Returns only unique items from the given arrays
 * @param items - arrays of items
 */
function unique(...items) {
  return (0, as_array_1.asArray)(new Set(items.flat()));
}
