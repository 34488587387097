"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.uid = uid;
/**
 *
 */
function uid() {
  return Math.random().toString(36).slice(-8);
}
