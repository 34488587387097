"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isKeyboardEvent = isKeyboardEvent;
/**
 *
 * @param event
 */
function isKeyboardEvent(event) {
  return event instanceof KeyboardEvent;
}
