"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAssignedInputs = getAssignedInputs;
const classes_1 = require("../classes");
const constants_1 = require("../constants");
const is_present_1 = require("./is-present");
/**
 *
 * @param template
 * @param componentSelectors
 */
function getAssignedInputs(template, componentSelectors) {
  const parser = new classes_1.NgDocHtmlParser(template);
  const rootElement = parser.find(constants_1.NG_DOC_DYNAMIC_SELECTOR) ?? parser.find(componentSelectors.find(selector => parser.find(selector)));
  if (!rootElement) {
    throw new Error(`Root element not found. Make sure that the template contains the component selector or the dynamic "${constants_1.NG_DOC_DYNAMIC_SELECTOR}" selector.`);
  }
  return Object.entries(rootElement.attrs ?? {}).filter(([, value]) => (0, is_present_1.isPresent)(value)).map(([key]) => key.replace(/\[|\]/g, ''));
}
