"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isNodeTag = isNodeTag;
/**
 *
 * @param node
 */
function isNodeTag(node) {
  return typeof node !== 'string' && typeof node !== 'number';
}
