import { FlexibleConnectedPositionStrategy } from '@angular/cdk/overlay';
import { NavigationEnd } from '@angular/router';
import { isPresent } from '@ng-doc/core';
import { toElement } from '@ng-doc/ui-kit/helpers';
import { ngDocZoneOptimize, fromSubscribe, ngDocZoneDetach } from '@ng-doc/ui-kit/observables';
import { fromEvent, merge, NEVER } from 'rxjs';
import { switchMap, filter, takeUntil, take, debounceTime, map, pairwise } from 'rxjs/operators';
class NgDocOverlayRef {
  constructor(overlayRef, overlayConfig, overlayContainer, ngZone, router, location) {
    this.overlayRef = overlayRef;
    this.overlayConfig = overlayConfig;
    this.overlayContainer = overlayContainer;
    this.ngZone = ngZone;
    this.router = router;
    this.location = location;
    this.overlayResult = null;
    this.opened = true;
    /* Closes overlay when it was outside click */
    this.afterOpen().pipe(switchMap(() => this.ngZone.runOutsideAngular(() => this.overlayRef.outsidePointerEvents())), filter(event => !!this.overlayConfig.closeIfOutsideClick && this.outsideClickChecker(event)), ngDocZoneOptimize(this.ngZone)).subscribe(() => this.close());
    /* Closes overlay when it was inner click */
    fromEvent(this.overlayRef.overlayElement, 'click').pipe(filter(() => !!this.overlayConfig.closeIfInnerClick), takeUntil(this.overlayRef.detachments()), ngDocZoneOptimize(this.ngZone)).subscribe(() => this.close());
    /* Closes overlay if route was changed */
    if (this.router && this.overlayConfig.disposeOnRouteNavigation) {
      this.router.events.pipe(filter(event => event instanceof NavigationEnd), ngDocZoneOptimize(this.ngZone), takeUntil(this.overlayRef.detachments())).subscribe(() => this.close());
    }
    if (this.location && this.overlayConfig.disposeOnNavigation) {
      fromSubscribe(this.location).pipe(takeUntil(this.overlayRef.detachments())).subscribe(() => this.close());
    }
    if (!this.overlayConfig.disableClose) {
      merge(this.overlayRef.backdropClick(), this.overlayRef.keydownEvents().pipe(filter(e => e.code === 'Escape'))).pipe(take(1), takeUntil(this.overlayRef.detachments())).subscribe(() => this.close());
    }
    /* Update position if origin changes its position */
    const origin = toElement(this.overlayConfig.origin);
    if (origin instanceof HTMLElement) {
      this.ngZone.onStable.pipe(debounceTime(10), map(() => origin.getBoundingClientRect()), pairwise(), filter(([a, b]) => isPresent(a) && isPresent(b) && (a.x !== b.x || a.y !== b.y || a.width !== b.width || a.height !== b.height)), ngDocZoneDetach(this.ngZone), takeUntil(this.overlayRef.detachments())).subscribe(() => this.overlayRef.updatePosition());
    }
  }
  /** Sets focus to overlay */
  focus() {
    this.overlayContainer.focus();
  }
  /** Overlay has focus */
  get isFocused() {
    return this.overlayContainer.isFocused;
  }
  /** Overlay is opened */
  get isOpened() {
    return this.opened;
  }
  /** Overlay has attached */
  get hasAttached() {
    return this.overlayRef.hasAttached();
  }
  /**
   * Closes overlay
   * @param closeResult
   */
  close(closeResult) {
    this.overlayResult = isPresent(closeResult) ? closeResult : null;
    this.afterClose().subscribe(() => void this.overlayRef.detach());
    this.overlayContainer.close();
    this.overlayRef.detachBackdrop();
    this.opened = false;
  }
  beforeOpen() {
    return this.overlayContainer.animationEvent.pipe(filter(event => event === 'beforeOpen'), take(1), map(() => void 0));
  }
  afterOpen() {
    return this.overlayContainer.animationEvent.pipe(filter(event => event === 'afterOpen'), take(1), map(() => void 0));
  }
  beforeClose() {
    return merge(this.overlayContainer.animationEvent.pipe(filter(event => event === 'beforeClose')), this.overlayRef.detachments()).pipe(take(1), map(() => this.overlayResult));
  }
  afterClose() {
    return merge(this.overlayContainer.animationEvent.pipe(filter(event => event === 'afterClose')), this.overlayRef.detachments()).pipe(take(1), map(() => this.overlayResult));
  }
  positionChanges() {
    return this.overlayConfig.positionStrategy instanceof FlexibleConnectedPositionStrategy ? this.overlayConfig.positionStrategy.positionChanges : NEVER;
  }
  outsideClickChecker(event) {
    const target = event.target;
    if (target instanceof Element) {
      const origin = toElement(this.overlayConfig.origin);
      if (origin instanceof HTMLElement) {
        return !origin.contains(target);
      }
    }
    return true;
  }
}

/**
 * Generated bundle index. Do not edit.
 */

export { NgDocOverlayRef };
